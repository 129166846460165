import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { generatePageURLNavigation } from "../../../../utils/additionalScriptHelpers";

const DropDownMatelas = ({ intl, onMouseLeave }) => {
  return (
    <ProductsDropdownEl>
      <DropDownList>
        <DropProductsSection>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/matelas-hybride-ressorts-ensaches/")}
            >
              Matelas hybride premium <sup>Best-seller</sup>
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/matelas-mousse-memoire-de-forme/")}
            >
              Matelas mousse mémoire de forme
            </a>
          </li>
          <li>
            <a onClick={onMouseLeave}
               href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/surmatelas/")}
            >
              Surmatelas réversible
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/matelas/")}
              style={{ textDecoration: "underline" }}
            >
              Comparer les matelas
            </a>
          </li>
        </DropProductsSection>
      </DropDownList>
      <ImageWrapper>
        <ImageBox>
          <ImageUrl
           href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/matelas-hybride-ressorts-ensaches/")}
          >
            Hybride premium
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/20717e12-b61e-4152-82d3-22bae3c5a01c.jpg"
          />
          <TagWrapper>Best-seller</TagWrapper>
          <TextWrapper>Hybride premium</TextWrapper>
        </ImageBox>
        <ImageBox>
          <ImageUrl
           href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/matelas-mousse-memoire-de-forme/")}
          >
            Mousse mémoire de forme
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/c0b60f6a-ad1d-4cbe-9678-d83d0887be00.jpg"
          />
          <TextWrapper>Mousse mémoire de forme</TextWrapper>
        </ImageBox>
        <ImageBox>
          <ImageUrl
           href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/surmatelas/")}
          >Surmatelas</ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/c9a50b3f-c6ed-41aa-bb58-a86a9aaa24a4.jpg"
          />
          <TextWrapper>Surmatelas</TextWrapper>
        </ImageBox>
      </ImageWrapper>
    </ProductsDropdownEl>
  )
}

const ProductsDropdownEl = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 30px;

  @media (max-width: 1200px) {
    padding: 0px 0px 20px 50px;
  }
`

const DropDownList = styled.div`
  width: 100%;
  min-width: 270px;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`

const DropProductsSection = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: 100%;

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  li:last-child {
    flex: 1;
    justify-content: flex-end;
  }

  li a sup {
    font-style: italic;
  }

  a {
    display: block;
    width: 100%;
    color: #000 !important;
    font-family: "Gotham Book";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    padding: 5px 10px;

    :hover {
      color: #000 !important;
      font-weight: bold;

      @media (max-width: 1200px) {
        font-weight: 500;
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    display: none;
  }
`

const ImageBox = styled.div`
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: center;
  object-fit: contain;
  position: relative;
  margin: 0px 25px 0px 0px;

  :last-of-type {
    margin-right: 0px;
  }
`

const ImageUrl = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  text-indent: -9999px;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
`

const TagWrapper = styled.div`
  width: auto;
  background: #fff;
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  top: 25px;
  left: 0px;
`

const TextWrapper = styled.div`
  width: auto;
  background: #fff;
  font-family: "Museo";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  bottom: 20px;
`

export default injectIntl(DropDownMatelas)
