import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const VentesChaiseSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1900px;
  margin: 0px auto;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin: 0px 10px 0px 0px;

  @media (max-width: 767px) {
    margin: 0px 5px 0px 0px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const ChristmasTextWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;

  @media (max-width: 600px) {
    padding: 20px;
  }
`

const Title = styled.div`
  font-size: 35px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  span {
    @media (max-width: 992px) {
      display: block;
    }
  }

  @media (max-width: 1600px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;

  @media (max-width: 600px) {
    flex-direction: row;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 120px;
  margin: 0px 0px 25px;

  @media (max-width: 600px) {
    max-width: 100px;
    margin: 0px 20px 0px 0px;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    max-width: 180px;
    text-align: left;
  }
`

const BottomText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;

  strong {
    font-weight: bold;
  }

  @media (max-width: 1600px) {
    font-size: 13px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
  background: #e7f0f3;
  margin: 0px 0px 0px 10px;

  @media (max-width: 767px) {
    margin: 0px 0px 0px 5px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 1;
`

const TopTagWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background: #fff;
  color: #262626;
  font-size: 20px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  padding: 5px 8px;
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
`

const TopSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  min-height: 400px;

  @media (max-width: 1200px) {
    min-height: 350px;
  }

  @media (max-width: 850px) {
    min-height: 300px;
  }

  @media (max-width: 480px) {
    min-height: 240px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0px 0px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const Button = styled(Link)`
  color: #262626;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border: 1px solid #262626;
  padding: 15px 20px;
  position: relative;
  margin: 0px;
  z-index: 2;

  :hover {
    color: #fff;
    background: #262626;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const VentesChaiseSection = ({ intl, data }) => {
  return (
    data && (
      <VentesChaiseSectionWrapper>
        <VentesContainer>
          <LeftSection>
            {data.url && (
              <AbsoluteButton to={data.url}>
                <ButtonTitle>Découvrir</ButtonTitle>
              </AbsoluteButton>
            )}
            <TopSection>
              {data.image && (
                <BannerImage
                  className="lazyload"
                  data-src={data.image}
                  alt={data.alt ? data.alt : ""}
                />
              )}
              {data.image && <TopTagWrapper>Nouveau</TopTagWrapper>}
            </TopSection>
          </LeftSection>
          <RightSection>
            {data.url && (
              <AbsoluteButton to={data.url}>
                <ButtonTitle>Découvrir</ButtonTitle>
              </AbsoluteButton>
            )}
            <ChristmasTextWrapper>
              {data.title && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              )}
              {data.text && (
                <TextWrapper>
                  <LogoImage src="https://static.percko.com/uploads/74389f14-523d-4311-87a7-a3aa8a478f81.jpg" />
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data.text,
                    }}
                  />
                </TextWrapper>
              )}
              {data.bottom_text && (
                <BottomText
                  dangerouslySetInnerHTML={{
                    __html: data.bottom_text,
                  }}
                />
              )}
              {data.url && (
                <ButtonWrapper>
                  <Button to={data.url}>Découvrir</Button>
                </ButtonWrapper>
              )}
            </ChristmasTextWrapper>
          </RightSection>
        </VentesContainer>
      </VentesChaiseSectionWrapper>
    )
  )
}

export default injectIntl(VentesChaiseSection)
