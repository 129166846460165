import { getCustomerDetailsObj, getSessionId } from "./localStorageHelper";

const md5 = require("md5");

const { isBrowser } = require("../context/ApolloContext");

export const addToCartScript = (
 productId,
 productTitle,
 variant,
 quantity,
 checkout,
 lang
) => {
  let klaviyoProducts = [];
  let productTitleArr = [];
  let shopifyProductId = productId
   .split("/")
   .slice(-1)[0]
   .split("?")[0];
  let shopifyVariantId = variant ? variant.id.split("/").slice(-1)[0] : "";

  checkout &&
  checkout.lineItems &&
  checkout.lineItems.forEach(product => {
    let productId = product.variant.product.id
     .split("/")
     .slice(-1)[0]
     .split("?")[0];

    klaviyoProducts.push({
      ProductID: productId,
      ProductName: product.title, //product_name
      ItemPrice: product.variant.price.amount,
      RowTotal: product.variant.price.amount,
      Quantity: product.quantity,
      ProductCategories: product.title, //product_category
      SKU: product.variant.sku, // sku
      ProductURL: product ? productUrl(product.title) : "",
      ImageURL: product ? productImageUrl(product.title) : ""
    });
    productTitleArr.push(product.title);
  });

  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_TYPE.toUpperCase(), //currency
        add: {
          actionField: {
            list: "product page"
          },
          products: [
            {
              // item_id: shopifyProductId, //product id
              item_id: shopifyVariantId, //product id
              item_name: productTitle, // product name
              item_variant: shopifyVariantId, //variant id
              item_variant_name: variant ? variant.title : "", // variant name
              item_brand: "Percko", // brand name
              item_category: productTitle, //product category
              sku: variant ? variant.sku : "", // sku number
              price: variant ? Number(variant.price).toFixed(0) : "", // product price
              quantity: quantity
            }
          ],
          klaviyoProducts: klaviyoProducts,
          productsTitle: productTitleArr,
          checkoutUrl: checkout ? checkout.webUrl : "",
          cartTotal: checkout
           ? checkout.subtotalPrice.amount
           : variant
            ? variant.price
            : ""
        }
      },
      language: lang ? lang : "fr"
    });
  }
};

export const removeFromCartScript = (
 productId,
 productTitle,
 variant,
 quantity,
 lang
) => {
  let shopifyProductId = productId
   ? productId
    .split("/")
    .slice(-1)[0]
    .split("?")[0]
   : "";
  let shopifyVariantId = variant ? variant.id.split("/").slice(-1)[0] : "";

  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "eec.remove",
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_TYPE.toUpperCase(),
        remove: {
          actionField: {
            list: "cart"
          },
          products: [
            {
              // item_id: shopifyProductId, //product id
              item_id: shopifyVariantId, //product id
              item_name: productTitle, // product name
              item_variant: shopifyVariantId, //variant id
              item_variant_name: variant ? variant.title : "", // variant name
              item_brand: "Percko", // brand name
              item_category: productTitle, //product category
              sku: variant ? variant.sku : "", // sku number
              price: variant ? Number(variant.price).toFixed(0) : "", // product price
              quantity: quantity
            }
          ]
        }
      },
      language: lang ? lang : "fr"
    });
  }
};

export const onCartPageScript = (checkout, lang) => {
  let products = [];
  var customerId = getCustomerDetailsObj("customerData")
   ? getCustomerDetailsObj("customerData")
    .id.split("/")
    .slice(-1)[0]
   : null;
  checkout?.lineItems.forEach(product => {
    let productId = product.variant.product.id
     .split("/")
     .slice(-1)[0]
     .split("?")[0];
    let variantId = product.variant
     ? product.variant.id.split("/").slice(-1)[0]
     : "";
    products.push({
      // item_id: productId,
      item_id: variantId,
      item_name: product.title, //product_name
      item_variant: variantId, // variant_id
      item_variant_name: product.variant.title, // variant_name
      item_brand: "Percko", // product_brand
      item_category: product.title, //product_category
      price: product.variant.price.amount,
      quantity: product.quantity,
      sku: product.variant.sku // sku
    });
  });
  if (isBrowser && process.env.NODE_ENV === "production") {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "eec.checkout",
      customerId: customerId,
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_TYPE.toUpperCase(),
        cart: {
          actionField: {
            value: checkout ? checkout.subtotalPrice.amount : "",
            step: 1,
            option: "cart"
          },
          products: products
        },
        checkout: {
          actionField: {
            step: 1,
            option: "cart"
          },
          products: products
        }
      },
      language: lang ? lang : "fr"
    });
  }
};

export const productGtmEvent = (product, variant, quantity, lang) => {
  let variantId = variant ? variant.id.split("/").slice(-1)[0] : "";
  let productId = product ? product.id.split("/").slice(-1)[0] : "";

  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_TYPE.toUpperCase(), //currency code
        detail: {
          actionField: {
            list: "product page"
          },
          products: [
            {
              // item_id: product ? productId : "", //product id
              item_id: variantId ? variantId : "", //product id
              item_name: product ? product.title : "", // product name
              item_variant: variantId ? variantId : "", //variant id
              item_variant_name: variant ? variant.title : "", // variant name
              item_brand: "Percko", // brand name
              item_category: product ? product.title : "", //product category
              sku: variant ? variant.sku : "", // sku number
              price: variant ? Number(variant.price).toFixed(0) : "", // product price
              quantity: quantity
            }
          ]
        }
      },
      language: lang ? lang : "fr"
    });
  }
};

export const variantUpdate = (product, variant, quantity, lang) => {
  let variantId = variant ? variant.id.split("/").slice(-1)[0] : "";
  let productId = product ? product.id.split("/").slice(-1)[0] : "";

  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_TYPE.toUpperCase(), //currency code
        detail: {
          actionField: {
            list: "product page"
          },
          products: [
            {
              // item_id: product ? productId : "", //product id
              item_id: variantId ? variantId : "", //product id
              item_name: product ? product.title : "", // product name
              item_variant: variantId ? variantId : "", //variant id
              item_variant_name: variant ? variant.title : "", // variant name
              item_brand: "Percko", // brand name
              item_category: product ? product.title : "", //product category
              sku: variant ? variant.sku : "", // sku number
              price: variant ? Number(variant.price).toFixed(0) : "", // product price
              quantity: quantity
            }
          ]
        }
      },
      language: lang ? lang : "fr"
    });
  }
};

export const userInfoEvent = lang => {
  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    var customerId = getCustomerDetailsObj("customerData")
     ? getCustomerDetailsObj("customerData")
      .id.split("/")
      .slice(-1)[0]
     : null;
    dataLayer.push({
      user_Id: customerId, // user_id from backend
      user_Email: getCustomerDetailsObj("customerData")
       ? getCustomerDetailsObj("customerData").email
       : null, //customer_email
      user_Phone: null,
      user_FirstName: getCustomerDetailsObj("customerData")
       ? getCustomerDetailsObj("customerData").firstName
       : null,
      user_LastName: getCustomerDetailsObj("customerData")
       ? getCustomerDetailsObj("customerData").lastName
       : null,
      user_Address1: null,
      user_City: null,
      user_Country: null,
      user_zipCode: null,
      language: lang ? lang : "fr"
    });
  }
};

export const faqReviewScript = (title, likeStatus, lang) => {
  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "faq.review_like",
      faq: {
        faq_name: title ? title : "",
        faq_like: likeStatus ? likeStatus : false
      },
      language: lang ? lang : "fr"
    });
  }
};

export const mobileStickyMenuScript = title => {
  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "generic_event",
      label: title,
      action: "click",
      category: "storylike",
      value: 1
    });
  }
};

export const formSubmitEventScript = (eventName, lang) => {
  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: eventName ? eventName : "newsletter_submit",
      language: lang ? lang : "fr"
    });
  }
};

export const pageViewEventScript = (title, lang) => {
  if (isBrowser) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      page_title: title ? title : "",
      language: lang ? lang : "fr"
    });
  }
};

export const addLyneHomeUrl = (lang, gender) => {
  let storePath = "";
  let pageName = `shop/lynehome/?Gender=${gender}`;

  let storeUrl = "";

  if (process.env.GATSBY_ACTIVE_ENV === "eur") {
    if (lang === "fr") {
      storePath =
       process.env.GATSBY_ACTIVE_ENV +
       `/shop/solutions-textiles/?Type=Gilet&Gender=${gender}`;
    } else {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${lang}/${pageName}`;
    }
  } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
    if (lang === "en") {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}`;
    } else {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${lang}/${pageName}`;
    }
  } else if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
    storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}`;
  } else if (process.env.GATSBY_ACTIVE_ENV === "usd") {
    storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}`;
  } else {
    if (lang === "fr") {
      storePath = `${pageName}`;
    } else {
      storePath = `${lang}/${pageName}`;
    }
  }

  if (
   process.env.NODE_ENV === "development" ||
   !process.env.GATSBY_ACTIVE_ENV ||
   process.env.GATSBY_ACTIVE_ENV === "general"
  ) {
    storeUrl = `http://localhost:8000/${storePath}`;
  } else {
    storeUrl = `https://${process.env.GATSBY_WEBSITE_URL}/${storePath}`;
  }

  return storeUrl;
};

export const addNewLyneHomeUrl = (lang, gender) => {
  let storePath = "";
  let pageName = `shop/lynehome/?Gender=${gender}&Color=BLUE`;

  let storeUrl = "";

  if (process.env.GATSBY_ACTIVE_ENV === "eur") {
    if (lang === "fr") {
      storePath =
       process.env.GATSBY_ACTIVE_ENV +
       `/shop/solutions-textiles/?Type=Gilet&Gender=${gender}&Collar=Rond&Color=Noir`;
    } else {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${lang}/${pageName}`;
    }
  } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
    if (lang === "en") {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}`;
    } else {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${lang}/${pageName}`;
    }
  } else if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
    storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}`;
  } else if (process.env.GATSBY_ACTIVE_ENV === "usd") {
    storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}`;
  } else {
    if (lang === "fr") {
      storePath = `${pageName}`;
    } else {
      storePath = `${lang}/${pageName}`;
    }
  }

  if (
   process.env.NODE_ENV === "development" ||
   !process.env.GATSBY_ACTIVE_ENV ||
   process.env.GATSBY_ACTIVE_ENV === "general"
  ) {
    storeUrl = `http://localhost:8000/${storePath}`;
  } else {
    storeUrl = `https://${process.env.GATSBY_WEBSITE_URL}/${storePath}`;
  }

  return storeUrl;
};

export const isLoadedScript = srcUrl => {
  return document.querySelectorAll("[src=\"" + srcUrl + "\"]").length > 0;
};

const productUrl = title => {
  let url = `https://${process.env.GATSBY_WEBSITE_URL}/${
   process.env.GATSBY_ACTIVE_ENV === "general"
    ? ""
    : process.env.GATSBY_ACTIVE_ENV
  }`;
  if (title === "LyneUp") {
    url += "/shop/lyneup/";
  } else if (title === "LyneHome") {
    url += "/shop/lynehome/";
  } else if (title === "LyneFit") {
    url += "/shop/lynefit/";
  } else if (title === "Matelas" || title === "1 Matelas + Oreiller") {
    url += "/shop/matelas/";
  } else if (title === "Oreiller") {
    url += "/shop/oreiller/";
  } else {
    url += "/shop/loop/";
  }
  return url;
};

const productImageUrl = title => {
  let imageUrl = "";
  if (title === "LyneUp") {
    imageUrl =
     "https://static.percko.com/uploads/391d2eb0-468a-49a1-9d8d-7c98070a58b5.png";
  } else if (title === "LyneHome") {
    imageUrl =
     "https://static.percko.com/uploads/fab644a3-ff38-43f3-bd2f-98251840995b.png";
  } else if (title === "LyneFit") {
    imageUrl =
     "https://static.percko.com/uploads/3400ecbe-2be6-4139-bf78-2ea119506aa3.png";
  } else if (title === "Matelas") {
    imageUrl =
     "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg";
  } else if (title === "1 Matelas + Oreiller") {
    imageUrl =
     "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg";
  } else if (title === "Oreiller") {
    imageUrl =
     "https://static.percko.com/uploads/70e1586f-d56c-4d3c-b368-9730b184495e.jpg";
  } else {
    imageUrl =
     "https://res.cloudinary.com/dcjf6omoh/image/upload/v1615916797/Percko_images_flux/Loop/Loop4_tdeoin.jpg";
  }
  return imageUrl;
};

export const generatePageURLNavigation = (baseURL, language, toUrl) => {
  let returnUrl = "";
  //localhost urls
  if(process.env.NODE_ENV === "development" || !process.env.GATSBY_ACTIVE_ENV ) {
    if (baseURL == "eur") {
      if (language === "fr") {
        returnUrl = toUrl;
      } else {
        returnUrl = "/" + language + toUrl;
      }
    } else if (baseURL == "cad") {
      if (language === "fr") {
        returnUrl = "/" +  language + toUrl;
      } else {
        returnUrl = toUrl;
      }
    } else if (baseURL == "gbp") {
      returnUrl = toUrl;
    } else if (baseURL == "usd") {
      returnUrl = toUrl;
    }
  } else {
    //staging/production  url
    if (baseURL == "eur") {
      if (language === "fr") {
        returnUrl = "/" + baseURL + toUrl;
      } else {
        returnUrl = "/" + baseURL + "/" + language + toUrl;
      }
    } else if (baseURL == "cad") {
      if (language === "fr") {
        returnUrl = "/" + baseURL + "/" + language + toUrl;
      } else {
        returnUrl = "/" + baseURL + toUrl;
      }
    } else if (baseURL == "gbp") {
      returnUrl = "/" + baseURL + toUrl;
    } else if (baseURL == "usd") {
      returnUrl = "/" + baseURL + toUrl;
    }
  }
  return returnUrl;
};
